<template>
  <b-card class="shadow-none border cursor-pointer">
    <b-card-text>
      <div class="d-flex justify-content-between">
        <img :src="require(`@/assets/images/icons/${drive.icon}`)" :alt="drive.name" height="38" />
        <div class="dropdown-items-wrapper">
          <div class="dropdown">
            <b-dropdown variant="link" no-caret toggle-class="p-0 mr-1" right>
              <template #button-content>
                <feather-icon role="button" icon="MoreVerticalIcon" size="16"
                  class="align-middle text-body" />
              </template>
              <b-dropdown-item>
                <feather-icon icon="RefreshCwIcon" size="16" class="align-middle text-body mr-25" />
                Rafraîchir
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="SettingsIcon" size="16" class="align-middle text-body mr-25" />
                Gérer
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
      <div class="my-1">
        <h5>{{ drive.name }}</h5>
      </div>
      <div class="d-flex justify-content-between mb-50">
        <span class="text-truncate">{{ drive.diskSpaceUsed }}GB Utilisé</span>
        <small class="text-muted">{{ drive.size }}GB</small>
      </div>
      <div class="progress progress-md mb-0" :class="`progress-bar-${drive.variant}`" style="height: 10px">
        <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="1" aria-valuemax="100"
          style="width: 1%"></div>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import { BDropdown, BDropdownItem, BCard, BCardText, } from 'bootstrap-vue'

export default {
  props: {
    drive: {
      type: Object,
      require: true,
    },
  },
  components: {
    BDropdown,
    BDropdownItem,

    BCard,
    BCardText,
  },
}
</script>
