<template>
  <div>
    <!-- drives area starts-->
    <drives />
    <!-- drives area ends-->

    <!-- Folders Container Starts -->
    <folders v-if="medias.length" :folders="medias" :class="selectedViewToggle" />
    <span v-else>Aucun Dossier n'a été crée</span>
    <!-- /Folders Container Ends -->
  </div>
</template>

<script>
import store from '@/store'
import { ref, onUnmounted, onMounted, computed, useAttrs } from '@vue/composition-api'

import Drives from '../contents/Drives.vue'
import Folders from '../contents/Folders.vue'
export default {
  components: { Folders, Drives },
  setup() {
    const medias = computed(() => store.getters['files_manager/all'])
    const attrs = useAttrs()
    const selectedViewToggle = computed(() => attrs['data-view'] != undefined ? attrs['data-view'] : '')

    return {
      selectedViewToggle,
      medias,
    }
  },
}
</script>