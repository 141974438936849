<template>
  <div class="drives">
    <div class="row">
      <div class="col-12">
        <h6 class="files-section-title mb-75">Drives</h6>
      </div>
      <div v-for="(drive, index) in getDrives" :key="index" class="col-lg-3 col-md-6 col-12">
        <drive-card :drive="drive"/>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import DriveCard from '@/components/file-manager/drives/DriveCard.vue'
import { drives } from '../fileTypes'

export default {
  components: {
    DriveCard,
  },
  setup() {
    const getDrives = ref(drives)

    return {
      getDrives,
    }
  },
}
</script>
