export const drives = [{
        name: 'Google Drive',
        icon: 'drive.png',
        diskSpaceUsed: '0',
        size: '0',
        variant: 'warning',
    },
    {
        name: 'Dropbox',
        icon: 'dropbox.png',
        diskSpaceUsed: '0',
        size: '0',
        variant: 'success',
    },
    {
        name: 'OneDrive',
        icon: 'onedrivenew.png',
        diskSpaceUsed: '0',
        size: '0',
        variant: 'primary',
    },
    {
        name: 'iCloud',
        icon: 'icloud-1.png',
        diskSpaceUsed: '0',
        size: '0',
        variant: 'info',
    },
]

export const files = [{
        name: 'Fichier.jpg',
        size: '12.6mb',
        date: '23 Mai 2019',
        img: 'jpg.png',
        alt: 'file-icon',
        accessed: 'Il y a 3 heures',
    },
    {
        name: 'Fichier.doc',
        size: '82kb',
        date: '25 Mai 2019',
        img: 'doc.png',
        alt: 'file-icon',
        accessed: 'Il y a 23 minutes',
    },
    {
        name: 'Fichier.txt',
        size: '54kb',
        date: '1 Mai 2019',
        img: 'txt.png',
        alt: 'file-icon',
        accessed: 'Il y a 43 minutes',
    },
    {
        name: 'Fichier.json',
        size: '200kb',
        date: '12 Mai 2019',
        img: 'json.png',
        alt: 'file-icon',
        accessed: 'Il y a 1 heure',
    },
]